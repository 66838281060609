<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />
    <CRow>
      <CCol xl="6" lg="12">
        <Filters />
        <Trucks />
      </CCol>
      <CCol xl="6" lg="12">
        <Map :mode="'dashboard'"/>
      </CCol>
    </CRow>

    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import Map from "../components/Map.vue";
import Filters from "../components/Filters.vue";
import Trucks from "../components/Trucks.vue";
import * as Socket from "../plugins/socket_client";
export default {
  name: "Dashboard",
  components: {
    Map,
    Filters,
    Trucks
  },
  computed: {},
  data() {
    return {
      loading: true,

      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",
    };
  },
  created() {
    Socket.connect();
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>